// Re-assigned maps
//
// Placed here so that others can override the default Sass maps and see automatic updates to utilities and more.

// scss-docs-start theme-colors-rgb
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value") !default;
// scss-docs-end theme-colors-rgb

// Utilities maps
//
// Extends the default `$theme-colors` maps to help create our utilities.

// Come v6, we'll de-dupe these variables. Until then, for backward compatibility, we keep them to reassign.
// scss-docs-start utilities-colors
$utilities-colors: $theme-colors-rgb !default;
// scss-docs-end utilities-colors

// scss-docs-start utilities-text-colors
$utilities-text: map-merge(
  $utilities-colors,
  (
    "black": to-rgb($black),
    "white": to-rgb($white),
    "body": to-rgb($body-color)
  )
) !default;
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, "$key", "text") !default;
// scss-docs-end utilities-text-colors

// scss-docs-start utilities-bg-colors
$utilities-bg: map-merge(
  $utilities-colors,
  (
    "black": to-rgb($black),
    "white": to-rgb($white),
    "body": to-rgb($body-bg)
  )
) !default;
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, "$key", "bg") !default;
// scss-docs-end utilities-bg-colors

// scss-docs-start utilities-border-colors
$utilities-border: map-merge(
  $utilities-colors,
  (
    "white": to-rgb($white)
  )
) !default;
$utilities-border-colors: map-loop($utilities-border, rgba-css-var, "$key", "border") !default;
// scss-docs-end utilities-border-colors

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;

$gutters: $spacers !default;
